<template>
<v-container style="height: 100%;background: #F5F8FA">
  <ShareReportDlg ref="ShareReportDlg" :ic_obj="{ic_parent:1, ic_parent_type:19}" />
  <v-container class="pa-5">
    <v-row justify="space-between">
      <div>
        <v-icon class="pa-1 report-mark">mdi-file-chart</v-icon>
        <span class="pl-3 text-subtitle-1">E-周报</span>
      </div>
      <v-btn color="primary" small @click="onShareReport">
        <v-icon class="text-subtitle-1 ">mdi-share</v-icon>
        <span>分享</span>
      </v-btn>
    </v-row>

    <v-row class="py-3">
      <span class="text-subtitle-2" style="color: rgb(191, 195, 199);">总览</span>
      <v-container class="zonggan">
        <v-row class="pa-3" justify="space-between">
          <span>周期: ({{this.dateRange.startStr}} - {{this.dateRange.endStr}})</span>
          <span v-if="false" style="color: rgb(255, 59, 78);">目标已有风险</span>
        </v-row>
        <v-divider light class="my-5"></v-divider>
        <span class="pa-3">
          <span>当前目标完成度</span>
          <span style="margin-left: 4px; background: rgb(46, 156, 255); padding: 0px 4px;">{{this.filterProgress.length>0?this.filterProgress[0].pr_percent:0}}%</span>
          <span style="width: 15px; margin-left: 1px;">, </span>
          <span style="margin-left: 4px;">环比增长</span>
          <span style="margin-left: 4px; background: rgb(12, 201, 145); padding: 0px 4px;">{{this.filterProgress.length>0?this.filterProgress[0].pr_up_percent:0}}%</span>
          <span style="width: 15px; margin-left: 1px;">, </span>
          <span style="margin-left: 1px; width: 165px;">具体任务执行情况如下:</span>
        </span>
        <div class="pa-3">
          <div>
            <span class="dots" style="left: 0px; bottom: 2px;"></span>
            <span class="pl-2">
              <span>本周应执行任务量</span>
              <span style="color: rgb(46, 156, 255);">{{currentTasks.length}}</span><span>, </span>
              <span>实际完成量</span>
              <span style="color: rgb(12, 201, 145);">{{completedTasks.length}}</span>
              <span>, </span><span>过期未完成量</span>
              <span style="color: rgb(255, 105, 82);">{{currentTasks.length - completedTasks.length }}</span><span>, </span>
              <span>本周无进展量</span>
              <span style="color: rgb(132, 117, 255);">{{currentTasks.length}}</span>
            </span>
          </div>
          <div class="pt-2">
            <span class="dots" style="left: 0px; bottom: 2px;"></span>
            <span class="pl-2">
              <span>有</span>
              <span style="    color: rgb(12, 192, 201);">{{filterTasks.length}}</span>
              <span>人存在过期未完成任务</span><span>, </span>
              <span style="color: rgb(255, 171, 36);">{{filterTasks.length}}</span>
              <span>人未更新任务进展</span>
            </span>
          </div>
        </div>
      </v-container>
    </v-row>

    <v-row class="py-3">
      <span class="text-subtitle-2" style="color: rgb(191, 195, 199);">详情</span>
      <v-container class="pa-0">
        <v-row>
          <v-col cols="5">
            <v-card elevation="0" class="pa-5" width="100%">
              <v-chart class="chart" :option="option1" />
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card elevation="0" class="pa-5" width="100%">
              <v-chart class="chart" :option="option2" />
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card elevation="0" class="pa-5" width="100%">
              <v-row class="pa-4">
                <span class="text-subtitle-1 font-weight-black">项目状态统计</span>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row class="">
                <v-col cols="6">
                  <v-card elevation="0" class="py-2">
                    <v-chart class="chart3" :option="option3" />
                  </v-card>
                </v-col>
                <v-col cols="6" class="d-flex align-center">
                  <div>
                    <div>
                      <span class="meiwancheng-text"></span>
                      <span style="font-size:10px;">未完成: {{100-Math.floor(this.itemObj.completed/this.itemObj.all*100)}}%</span>
                    </div>
                    <div>
                      <span class="yiwancheng-text"></span>
                      <span style="font-size:10px;">已结束: {{Math.floor(this.itemObj.completed/this.itemObj.all*100)}}%</span>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-divider></v-divider>
              </v-row>

              <v-row class="d-flex justify-center">
                <div class="pa-3 ">
                  <div class=""><span class="pa-2">{{this.itemObj.all}}</span><span class="text-subtitle-1">已过期项目数</span></div>
                  <div class=""><span class="pa-2">{{this.itemObj.completed}}</span><span class="text-subtitle-1">本周完成项目数</span></div>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row class="py-3">
      <v-container>
        <v-row class="table-title">本周任务执行统计表</v-row>
        <v-row>
          <v-card width="100vw" elevation="0">
            <v-data-table :headers="headers" :items="filterTasks" :items-per-page="5"></v-data-table>
          </v-card>
        </v-row>
      </v-container>
    </v-row>
    <v-row class="py-3">
      <span class="pa-4">
        <v-icon class="pr-3" style="color: rgb(56, 186, 114); font-size:19px;">mdi-message-processing</v-icon>
        <span class="text-subtitle-1">沟通</span>
      </span>
      <v-container class="pa-0">
        <v-card class="pa-3" elevation="0">
          <Intercom :ic_obj="{ic_parent:(this.$route.query.workId*1+this.$route.query.objectId*1), ic_parent_type:18}" @submit="" />
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</v-container>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'
import moment from 'moment'

import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  LineChart,
  PieChart
} from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, {
  THEME_KEY
} from "vue-echarts";
import {
  Routes
} from "@/constants/routes.js";

use([
  CanvasRenderer,
  LineChart,
  PieChart,
  TitleComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent
]);
import 'echarts/lib/component/visualMap'

export default {
  name: "EReportPage",
  components: {
    VChart,
    Intercom: () => import("@/components/common/Intercom/Intercom.vue"),
    ShareReportDlg: () => import("@/components/okrgoal/okrDetail/E-weekly/ShareReportDlg.vue"),
  },
  provide: {
    [THEME_KEY]: "light"
  },
  data() {
    return {
      tab: null,
      headers: [{
          text: '名次',
          sortable: false,
          value: 'num',
          align: 'center',
          width: '100px',
        },
        {
          text: '人员',
          value: 'task_owner_name',
          sortable: false,
          align: 'center',
          width: '100px',
        },
        {
          text: '应执行任务量',
          value: 'task_all',
          align: 'center',
          width: '150px',
        },
        {
          text: '应完成任务量',
          value: 'task_not_completed',
          align: 'center',
          width: '150px',
        },
        {
          text: '实际完成量',
          value: 'task_completed',
          align: 'center',
          width: '150px',
        },
        {
          text: '完成率',
          value: 'task_percent',
          align: 'center',
          width: '150px',
        },
        {
          text: '未更新进展数',
          value: 'task_not_progress',
          width: '150px',
        },
        {
          text: '未更新进展率',
          value: 'task_not_progress_rate',
          width: '150px',
        },
        {
          text: '平均更新进展次数',
          value: 'task_avg_progress',
          width: '200px',
        },
      ],
      desserts: [{
        name: '1',
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: '1%',
      }, {
        name: '2',
        calories: 213,
        fat: 7.0,
        carbs: 24,
        protein: 4.0,
        iron: '1%',
      }, ],
      dateRange: {
        startDate: null,
        endDate: null,
        startStr: '',
        endStr: '',
      }
    };
  },
  created() {
    this.dateRange.startDate = new Date(this.$route.query.workId * 1000 * 60 * 60 * 24);
    this.dateRange.endDate = new Date(this.$route.query.workId * 1000 * 60 * 60 * 24);
    this.dateRange.endDate.setDate(this.dateRange.endDate.getDate() + 6);
    this.dateRange.startStr = moment(this.dateRange.startDate).format("YYYY/MM/DD");
    this.dateRange.endStr = moment(this.dateRange.endDate).format("YYYY/MM/DD");

    this.getDetail(this.$route.query.objectId);
    this.getProgress({
      pr_parent: this.$route.query.objectId,
      pr_parent_type: 0
    });
    
  },
  methods: {
    ...mapActions('okr', ['getOkrs', 'getDetail']),
    ...mapActions('progress', ['getProgress']),
    onShareReport() {
      this.$refs.ShareReportDlg.open({
        ob_name: this.okrDetail.ob_name,
        e_start: this.dateRange.startStr,
        e_end:  this.dateRange.endStr ,
        path: Routes.OKR_EREPORT_PAGE + '?objectId=' + this.$route.query.objectId + '&workId=' + this.$route.query.workId
      });
    },
  },
  computed: {
    ...mapGetters('okr', ['okrObj', 'okrDetail']),
    ...mapGetters('progress', ['progress']),
    ...mapGetters('auth', ['authUser']),
    filterProgress() {
      return this.progress.filter(pr => {
        return pr.created_at <= this.dateRange.endDate
      })
    },
    filterTaskArray() {
      let arr = [];
      // this.filterTasks.map(item=>{
      // 	arr.push(item);
      // })
      return arr;
    },
    filterTasks() {
      let fTasks = {};
      let ret = [];
      this.tasks.map(task => {
        let owner = task.task_owner;
        let completed = (task.task_status == 1) * 1;
        let notcompleted = (task.task_status != 1) * 1;
        if (fTasks[owner] == null) {
          fTasks[owner] = ret.length;
          ret.push({
            num: ret.length + 1,
            task_owner: owner,
            task_owner_name: task.task_owner_name,
            task_all: 1,
            task_completed: completed,
            task_not_completed: notcompleted,
            task_percent: completed * 100 + '%',
            task_not_progress: 0,
            task_not_progress_rate: 0 + '%',
            task_avg_progress: 0,
          });
        } else {
          ret[fTasks[owner]].task_all += 1;
          ret[fTasks[owner]].task_completed += completed;
          ret[fTasks[owner]].task_not_completed += notcompleted;
          ret[fTasks[owner]].task_percent = Math.floor(ret[fTasks[owner]].task_not_completed / ret[fTasks[owner]].task_all * 100) + '%';
        }
      })
      return ret;
    },
    completedTasks() {
      return this.currentTasks.filter(task => {
        return task.task_status == 1;
      })
    },
    currentTasks() {
      if (!this.okrDetail) {
        return tasks;
      }
      return this.tasks.filter(task => {
        return !(task.task_start_date > this.dateRange.endDate || task.task_end_date < this.dateRange.startDate)
      })
    },

    tasks() {
      let tasks = [];
      if (!this.okrDetail) {
        return tasks;
      }
      if (this.okrDetail.ob_tasks) {
        this.okrDetail.ob_tasks.map(task => {
          tasks.push(task);
        });
      }
      if (this.okrDetail.ob_results) {
        this.okrDetail.ob_results.map(kr => {
          kr.kr_tasks.map(task => {
            tasks.push(task);
          });
        });
      }
      return tasks;
    },
    itemObj() {
      let items = [];
      let itemObj = {
        all: 0,
        completed: 0,
      }
      if (!this.okrDetail) {
        return itemObj;
      }
      if (this.okrDetail.ob_items) {
        this.okrDetail.ob_items.map(item => {
          items.push(item);
        });
      }
      if (this.okrDetail.ob_results) {
        this.okrDetail.ob_results.map(kr => {
          kr.kr_items.map(item => {
            items.push(item);
          });
        });
      }
      itemObj.all = items.length;
      itemObj.completed = items.filter(item => {
        return item.item_status == 1;
      }).length;
      return itemObj;
    },
    option1: function () {
      let days = [];
      let realCnt = [];
      let wantCnt = [];
      let dateLabel = new Date(this.dateRange.startDate);
      for (let i = 0; i < 7; i++) {
        days.push(moment(dateLabel).format("MM-DD"));
        dateLabel.setDate(dateLabel.getDate() + 1);
        realCnt.push(this.currentTasks.length);
        wantCnt.push(this.currentTasks.length - i);
      }
      return {
        title: {
          text: '任务燃尽图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'white'
            }
          }
        },
        legend: {
          data: ['实际剩余任务数', '理想剩余任务数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: days
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
            name: '实际剩余任务数',
            type: 'line',
            // areaStyle: {
            //   color: 'skyblue'
            // },
            lineStyle: {
              color: 'lightBlue'
            },
            emphasis: {
              focus: 'series'
            },
            data: realCnt
          },
          {
            name: '理想剩余任务数',
            type: 'line',
            // areaStyle: {
            //   color: 'yellow'
            // },
            lineStyle: {
              color: 'lightGreen'
            },
            emphasis: {
              focus: 'series'
            },
            data: wantCnt
          },
        ],
      }
    },

    option2: function () {
      let days = [];
      let realCnt = [];
      let wantCnt = [];
      let dateLabel = new Date(this.dateRange.startDate);
      for (let i = 0; i < 7; i++) {
        days.push(moment(dateLabel).format("MM-DD"));
        dateLabel.setDate(dateLabel.getDate() + 1);
        realCnt.push(Math.floor(this.currentTasks.length + this.completedTasks.length));
        wantCnt.push(Math.floor(this.currentTasks.length + this.completedTasks.length / 6 * i));
      }
      return {
        title: {
          text: '任务进展走势图'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'white'
            }
          }
        },
        legend: {
          data: ['累计完成任务数', '累计任务总数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: days
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
            name: '累计完成任务数',
            type: 'line',
            // areaStyle: {
            //   color: 'skyblue'
            // },
            lineStyle: {
              color: 'lightBlue'
            },
            emphasis: {
              focus: 'series'
            },
            data: realCnt
          },
          {
            name: '累计任务总数',
            type: 'line',
            // areaStyle: {
            //   color: 'yellow'
            // },
            lineStyle: {
              color: 'purple'
            },
            emphasis: {
              focus: 'series'
            },
            data: wantCnt
          },
        ],
      }
    },

    option3: function () {
      let data = [{
        name: (100 - Math.floor(this.itemObj.completed / this.itemObj.all * 100)) + '%',
        value: 100 - Math.floor(this.itemObj.completed / this.itemObj.all * 100)
      }, {
        name: Math.floor(this.itemObj.completed / this.itemObj.all * 100) + '%',
        value: Math.floor(this.itemObj.completed / this.itemObj.all * 100)
      }];
      let legendData = ['%', '%'];
      return {
        title: {
          text: "",
          left: "left",
          show: true
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "right",
          itemWidth: "70",
          itemHeight: "120",
          data: legendData,
          itemStyle: {
            color: "white",
            borderColor: "grey",
            borderWidth: "1"
          },
          show: false
        },
        avoidLabelOverlap: false,
        series: [{
          name: "",
          type: "pie",
          radius: [25, 40],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            fontSize: '16px',
            fontWeight: 'bold'
          },
          center: ["50%", "60%"],
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }],
        color: ['rgb(100, 181, 254)', 'rgb(255, 159, 145)'],
      }
    },

  },
};
</script>

<style scoped>
.meiwancheng-text {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 20px;
  background: rgb(100, 181, 254);
  border-radius: 2px;
}

.yiwancheng-text {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 20px;
  background: rgb(255, 159, 145);
  border-radius: 2px;
}

.chart {
  height: 300px;
}

.chart3 {
  height: 130px;
}

.table-title {
  height: 46px;
  font-size: 15px;
  border-bottom: 1px solid rgb(240, 242, 245);
  color: rgb(250, 251, 252);
  background: rgb(140, 146, 203);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.dots {
  width: 7px;
  height: 7px;
  position: relative;
  background: rgb(230, 232, 235);
  display: inline-block;
  border-radius: 50%;
}

.zonggan {
  width: 100%;
  height: 192px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(240, 242, 245);
  border-radius: 2px;
  margin-top: 8px;
}

.zonggan span {
  font-size: 16px;
}

.empty {
  min-height: 50px;
  width: 80%;
  border: 1px dotted #777;
  margin: 0 auto;
}

.report-mark {
  font-size: 18px;
  background: rgb(12, 201, 145);
  border-radius: 3px;
  color: white;
}

.icon-1 {
  color: rgb(40, 121, 255);
}
</style>
